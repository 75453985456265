.invoice-container{
    background-color: #fff;
    padding: 15px;
    position: relative;
}
.width-100{
    width: 100%;
}
.width-90{
    width: 90%;
}
.width-80{
    width: 80%;
}

.width-70{
    width: 70%;
}

.width-60{
    width: 60%;
}
.width-50{
    width: 50%;
}
.width-40{
    width: 40%;
}
.width-30{
    width: 30%;
}
.width-20{
    width: 20%;
}
.width-10{
    width: 10%;
}

.width-8{
    width: 8%;
}
.width-5{
    width: 5%;
}
.float-right{
    float: right;
}
.img-logo {
    width: 170px;
}
.img-stamp {
    width: 50%;
}
.row-height{
    height: 40px;
}


.invoice-container th, .invoice-container th .MuiBox-root, .invoice-container tr, .invoice-container td {
    padding: 3px 10px !important;
    border: 1px solid #777 !important;
    font-size: 11px !important;
}


.invoice-container .hide-border ,.invoice-container .hide-border td{
    border: 1px solid #777 !important;
    border-top: none !important;
    border-bottom: none !important;
    height: 10px;
}

.invoice-container .hide-border-top ,.invoice-container .hide-border-top td{
    border: 1px solid #777 !important;
    border-top: none !important;
}

.invoice-container .hide-border-bottom ,.invoice-container .hide-border-bottom td{
    border: 1px solid #777 !important;
    border-bottom: none !important;
}

.row-height-small tr, .row-height-small td{
    height: 20px;
    padding: 0px 11px !important;
}
.row-height-small span{
    text-transform: capitalize;
}

.row-autho td, tr.row-autho {
    height: 100px;
    border: none !important;
}

.row-autho-top td, tr.row-autho-top {
    height: 20px;
    border: none !important;
}

.signature-table{
    border: 1px solid #777;
    border-left: 0px
}

.table-details-right tr, .table-details-right td{
    border-left: 0px !important;
}

.footer-invoice{
    border-top: 3px solid #004781;
    padding-top: 10px;
    clear:both;
    position:relative;
    bottom:0;
    width: 100%;
    margin-top: 20px;
    
}
/* .footer-top{
    position: relative;
    height: 200px;
} */
.footer-invoice p{
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.footer-invoice img{
    border-right: 3px solid #004781;
    padding-right: 15px;
}

.width-footer-logo{
    width: 200px;
}

.qr-table img{
    width: 110px;
    height: 110px;
}
.qr-side-table{
    margin: auto 0;
}
.qr-side-table ul{
    list-style: none;
    font-size: 13px;
    padding: 10px;
    margin-bottom: 0;  
}
.qr-side-table ul li b{
    display: block;
    flex-basis: 15%;
}

.qr-side-table ul li b span{
    flex-basis: 80%;
}
.qr-side-table ul li{
    display: flex;
    margin-bottom: 3px;
    min-width: 100%;
    display: inline-flex;
}

@media print{
    .MuiDrawer-root, header{
        display: none !important;
    }
    .invoice-container .invoice3-row{
        zoom: 90%;
        margin: auto;
    }
    .invoice-container {
        background-color: #fff;
        padding: 0px;
        position: relative;
        height: 100%;
        min-height: 95vh;
    }
    .footer-invoice{
        border-top: 3px solid #004781;
        clear:both;
        position:absolute;
        bottom:0px;
        width: 100%;
    }
}

.proforma-links{
    font-size: 15px;
    margin-left: 6%;
}

.proforma-links a{
    display: inline-block;
    border: 1px solid #0977bc;
    color: #0977bc;
    background-color: #fff;
    padding: 4px 15px;
    font-weight: 500;
    font-size: 11px;
    text-transform: uppercase;
    border-radius: 4px;
    margin-right: 10px;
}

.proforma-links a:hover{
    color: #fff;
    background-color: #0977bc;
    transition: ease-in-out 0.3s;
}

.order-heading{
    display: flex;
    align-items: center;
}

.MuiOutlinedInput-input.Mui-disabled {
    opacity: 1;
    color: #444;
    -webkit-text-fill-color: #444 !important;
    background: #f2f2f294;
    border-radius: 4px;
    height: 15px;
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #d2d6da !important;
}

.invoice-container .no-border{
    border: none !important;
}


.invoice-container .no-border-left{
    border: 1px solid #777;
    border-left: none !important;
}

.invoice-container .no-border-right{
    border: 1px solid #777;
    border-right: none !important;
}

.invoice-dis{
    font-size: 12px;
    text-align: center;
    margin-top: 20px !important;
}