
th, td, tr, tr .MuiBox-root, td .MuiBox-root , th .MuiBox-root{
    color: #000 !important;
}

th, th .MuiBox-root, tr, td{
    padding: 0px 11px !important;
    border: 1px solid #ccc !important;
    font-size: 13px !important;
}
input.form-control, input.form-select{
    font-size: 0.875rem !important;
    padding: 9px !important;
}
.btn-add{
    display: flex;
}
.form-label{
    margin-bottom: 0px !important;
}

.primary-text{
    color:#0a76bc !important;
}

.copy-btn{
    margin-bottom: -40px;
}

label{
    z-index: auto !important;
}

.form-wrapper{
    padding: 20px 10%;
}
.MuiTextField-root {
    width: 100%;
}
.mui-error fieldset{
    border: 1px solid #F44335 !important;
}

.mui-error:hover fieldset{
    border: 1px solid #F44335 !important;
}
.react-date-picker__wrapper{
    border: none !important;
}

fieldset{
    top: 0px !important;
}

.MuiSelect-select{
    height: 44px !important;
}

.bg-image-wrapper{
    position: absolute;
    width: 100%;
    opacity: 1;
    left: 0;
    top: 0px;
    background: transparent;
}
.MuiAutocomplete-popper{
    width: max-content !important;
}
.MuiFormHelperText-root {
    margin: 4px 0px 0px!important;
}
header{
    background-color: #fff !important;
    z-index: 99 !important;
}

.bg-white{
    padding:  15px !important;
    border-radius: 6px;
    overflow: visible;
}

.terms-text{
    line-height: 10px;
    font-size: 13px;
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: #ccc !important;
    border: 0;
    opacity: .25;
}

.box-container{
    padding: 20px;
    background: #fff;
    box-shadow: inset 0rem 0rem 0.0625rem 0.0625rem rgb(255 255 255 / 90%), 0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%);
    -webkit-backdrop-filter: saturate(200%);
    border-radius: 10px;
}

.MuiSelect-icon {
    display: block !important;
    font-size: 20px !important;
}

.pull-right{
    float:  right;
}

.pull-left{
    float:  left;
}

.text-right{
    text-align: right;
}

.text-left{
    text-align: left;
}
.modal-width{
    width: auto !important;
}

.text-sm{
    font-size: 14px !important;
    width: 100%;
}

.text-xs{
    font-size: 11px !important;
}

.text-normal{
    font-size: 14px !important;
}

.MuiInputLabel-shrink {
    background-color: #fff;
}

label{
    overflow: visible !important;
}

textarea:focus, input:focus{
    outline: none !important;
}

.MainContainer{
    padding-left: 15px;
    padding-right: 15px;
}

.pagination button{
    color: #000 !important;
}

.MuiPopover-paper{
    box-shadow: 0rem 0.25rem 0.375rem -1.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06) !important;
}

.modal-dialog{
    min-width: 700px;
    margin-top: 10%;
}
/* Error bagde color */

.status {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.bg-pending{
    background-color: #000 !important;
}
.bg-approved{
    background-color: #198038 !important;
}
.bg-in-draft{
    background-color: #6f6f6f !important;
}
.bg-submitted{
    background-color: #0f62fe !important;
}
.bg-in-review{
    background-color: #eb6200 !important;
}
.bg-qc-rejected{
    background-color: #da1e28 !important;
}
.MuiButton-containedError{
    background-color: #da1e28 !important;
    border: 1px solid #da1e28 !important;
}
.MuiPaginationItem-textPrimary.Mui-selected {
    color: #fff !important;
    background-color: #1a73e8 !important;
    font-weight: 500;
    font-size: 14px;
}
.MuiPaginationItem-page  {
    font-weight: 500 !important;
    font-size: 14px !important;
}
.table-dropdown .MuiFormControl-root{
    height: 30px !important;
    margin: 10px 0;
}

.MuiLinearProgress-root{
    overflow: hidden !important;
}

.MuiOutlinedInput-input{
    height: auto !important;
    font-size: 14px !important;
}
.MuiAutocomplete-inputRoot{
    padding: 9px !important;
}

.MuiOutlinedInput-root{
    height: 40px !important;
}
textarea {
    padding: 10px;
    border-radius: 6px;
    font-size: 14px !important;
    border-color: #d2d6da;
}

.country-code-select{
    flex-basis: 20%;
}

.MainContainer{
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px !important;
}


a.active  .MuiListItem-root .MuiBox-root{
    background: linear-gradient(195deg, #49a3f1, #1A73E8) !important;
}

.client-text{
    display: inline-block;
    width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
    margin-top: 8px;
}

.poc-text{
    display: inline-block;
    width: 100px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
    margin-top: 8px;
}

/* .client-text:hover{
    display: inline-block;
    width: max-content;
    white-space: nowrap;
    overflow: auto !important;
    text-overflow:inherit;
} */

header {
    background-color: #fff !important;
    padding: 0px !important;
    min-height: 30px !important;
    top: 0px !important;
    margin-bottom: 10px;
}
.header-top {
    padding: 5px 15px !important;
    border-radius: 0px;
    /* justify-content: flex-start !important; */
}

.logo-img{
    width: 8rem;
}

.mt-md-3{
    margin-top: 15px;
}
.modal-link{
    cursor: pointer;
    margin-left: 20px;
    text-decoration: underline;
    font-weight: 600;
}
@media (max-width: 786px) {
    .modal-dialog {
        min-width: auto;
    }
    .mt-xs-0{
        margin-top: 0px !important;
    }
    .abc{
        padding: 10px !important;
    }
    .MuiCardContent-root{
        padding: 10px !important;
    }

    .MuiTypography-h4 {
        font-size: 20px !important;
        padding: 0px 10px;
    }

    label{
        font-size: 12px !important;
    }

    .text-sm {
        font-size: 13px !important;
        width: 100%;
    }

    .ImgWrapper{
        margin: 10px 1px !important;
    }

    .text-para {
        font-size: 12px !important;
    }
    .btn, button{
        font-size: 12px !important;
    }

    .MainContainer{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.dashboard-container{
    margin-top: 30px;
    padding: 50px;
    background: #fff;
}

.MuiTableHead-root{
    display: table-header-group !important;
}

.MainContainer{
    padding-top: 1px !important; 
}

label{
    font-size: 13px;
}

legend span{
    display: none !important;
}


.order-table th, .order-table th .MuiBox-root, .order-table tr, .order-table td {
    border: none !important;
    border-bottom: 1px solid #ddd;
}

.order-table th, .order-table th .MuiBox-root {
    font-size: 10px !important;
    padding:5px !important; 

}

.MuiButton-root{
    color: #fff !important;
}

.MuiButton-containedSecondary{
    background-color: #4CAF50 !important;
}
.filter-search{
    margin-top: 35px !important;
  }
    .btn-danger{
        background: rgb(216, 44, 44) !important;
    }
  .clear-btn{
    margin-left: 10px !important;
    background: #FF9800 !important;
  }

  .filter-search-box .MuiGrid-item{
    padding-top: 0px !important;
  }

  legend{
    float: right !important;
  }

  .MuiButton-sizeSmall{
    color: #fff !important;
    padding: 3px 15px !important;
    height: 20px !important;
    min-height: 27px !important;
    width: 20px;
    min-width: max-content !important;
  }

  .material-icons-round{
    color: #fff;
  }

  .MuiDrawer-docked .MuiListItemText-root span{
    color: #fff;
  }

  .breadcrumbs-custom{
    margin-bottom: 0px !important;
  }
  .purchase-price{
    background-color: yellow;
  }